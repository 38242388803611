import "./Input.scss";
import { ChangeEvent } from "react";

interface InputProps {
	type: string

	id?: string
	name?: string
	className?: string
	placeholder?: string
	value?: string | number
	defaultValue?: string
	disabled?: boolean
	style?: React.CSSProperties

	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export default function Input(props: InputProps) {
	return <input type={props.type}
		className={"input " + (props.className || "")}
		placeholder={props.placeholder}
		name={props.name}
		id={props.id}
		value={props.value}
		disabled={props.disabled}
		style={props.style}
		defaultValue={props.defaultValue} onChange={props.onChange} />
}
