import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef, createColumnHelper, FilterFn, RowData, RowModel } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { useState,useRef } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";

import AbunTable, { IndeterminateCheckbox } from "../../components/AbunTable/AbunTable";
import { getAllUserData, retryFn } from "utils/api";

import "./ExportUsers.scss";

export interface AllUsersData {
	id: number
	username: string
	email: string
	website: string
	titles_generated: number
	articles_generated: number
	last_login: string
	date_joined: string
	keywords: number
	keyword_projects: number
	plan_name: number
}

export default function ExportUsers() {
	// ---------------------- NON STATE CONSTANTS ----------------------
	const pageSizes = [50, 100, 150, 300, 500,];

	// ---------------------- STATES ----------------------
    const [selectedRows, setSelectedRows] = useState<RowModel<RowData>>();
	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;
	const [selectedPlan, setSelectedPlan] = useState('all');

	// --------------------- REFS ---------------------
    const errorAlert = useRef<any>(null);
    const successAlert = useRef<any>(null);
	
	// ---------------------- FUNCTIONS ----------------------
	function selectedRowsSetter(rowModel: RowModel<RowData>) {
		setSelectedRows(rowModel);
	}

	const handleExportAllUser = async () => {
		
		try {
		  const allUsersData = await getAllUserData(true);
		  // Convert the selected data to CSV
		  const headers = [
			"id", "username", "email", "website", "titles_generated", "articles_generated", 
			"last_login", "date_joined", "keywords", "keyword_projects", "plan_name"];

			const csvRows:Array<String> = [];
			csvRows.push(headers.join(","));
			
			allUsersData.data.users.forEach(user => {
				const row = headers.map(header => `"${user[header]}"`).join(",");
				csvRows.push(row);
			});

			// Create a Blob from the CSV data
			const csvData = csvRows.join("\n");
			const blob = new Blob([csvData], { type: "text/csv" });

			// Create an anchor element to trigger the download
			const link = document.createElement("a");
			link.href = URL.createObjectURL(blob);
			link.download = "all-users.csv"; // Set the filename
			link.click();
			successAlert.current?.show("All users data exported successfully!");

		} catch (err) {
			errorAlert.current?.show(err);
		} 
	  };
	
	// ---------------------- TABLE CUSTOM FILTERS ----------------------
	const joinedDateFilter: FilterFn<any> = (row, columnId, value: [Date, Date]) => {
		if (value[0] === null && value[1] === null) {
			return true
		} else {
			let columnDateString: string = row.getValue(columnId);
			if (columnDateString) {
				let columnDate = DateTime.fromISO(columnDateString);
				let filterStartDate = DateTime.fromISO(value[0].toISOString());
				let filterEndDate = DateTime.fromISO(value[1].toISOString());
				return (filterStartDate <= columnDate) && (filterEndDate >= columnDate)
			} else {
				return false;
			}
		}
	}

	// ---------------------- TABLE COLUMN DEFS ----------------------
	const columnHelper = createColumnHelper<AllUsersData>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: AllUsersData) => row.email, {
            id: 'checkbox',
            header: ({ table }) => (
                <IndeterminateCheckbox
                    {...{
                        checked: table.getIsAllRowsSelected(),
                        indeterminate: table.getIsSomeRowsSelected(),
                        onChange: table.getToggleAllRowsSelectedHandler(),
                    }}
                />
            ),
            cell: ({ row }) => (
                <IndeterminateCheckbox
                    {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }}
                    name={"exportSelectedUsers"}
                    value={row.original.email}
                />
            ),
            enableGlobalFilter: true,
        }),
		columnHelper.accessor((row: AllUsersData) => row.email, {
			id: 'email_id',
			header: "Email ID",
			cell: props => {
			return (
				<Link to={`/all-users/${props.row.original.id}`}>
                    <span>{props.row.original.email}</span>
                </Link>
				)
			},
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: AllUsersData) => row.username, {
			id: 'username',
			header: "Name",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: AllUsersData) => row.plan_name, {
			id: 'plan_name',
			header: "Plan",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: AllUsersData) => row.website, {
			id: 'website',
			header: "Website",
			// NOTE: "---" will still be seen as undefined or null in filter functions.
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: AllUsersData) => row.titles_generated, {
			id: 'titles_generated',
			header: "Titles",
			cell: info => info.getValue(),
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: AllUsersData) => row.articles_generated, {
			id: 'articles_generated',
			header: "Articles",
			cell: info => info.getValue(),
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: AllUsersData) => row.keywords, {
			id: 'keywords',
			header: "KWs",
			cell: info => info.getValue(),
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: AllUsersData) => row.keyword_projects, {
			id: 'keyword_projects',
			header: "KW Proj",
			cell: info => info.getValue(),
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: AllUsersData) => row.last_login, {
			id: 'last_login',
			header: "Last Login (IST)",
			cell: info => DateTime.fromISO(info.getValue()).setZone("Asia/Kolkata").toLocaleString(DateTime.DATETIME_MED),
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: AllUsersData) => row.date_joined, {
			id: 'date_joined',
			header: "Date Joined (IST)",
			cell: info => DateTime.fromISO(info.getValue()).setZone("Asia/Kolkata").toLocaleString(DateTime.DATETIME_MED),
			enableGlobalFilter: false,
			filterFn: joinedDateFilter
		}),
	]


	// ==============================================================
	// ---------------------- MAIN RENDER CODE ----------------------
	// ==============================================================
	return (
		<div className={"all-users-container mt-4"}>
			<div className={"table-controls table-controls-header"}>
				<div className={"table-custom-controls"}>
					<label className={"label is-flex is-align-items-center table-custom-control--item"}>
						<FontAwesomeIcon icon={['far', 'calendar-days']} size={'xl'}/>&nbsp;&nbsp;&nbsp;
						<DatePicker
							selectsRange={true}
							startDate={startDate}
							endDate={endDate}
							onChange={(update) => {
								setDateRange(update);
							}}
							peekNextMonth
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							className={"input"}
							isClearable={true}
							placeholderText={"Click for Date Filter"}
						/>
					</label>
					<div className={"select table-custom-control--item"}>
						<select value={selectedPlan} onChange={e => setSelectedPlan(e.target.value)}>
							<option value="all">All Plans</option>
							<option value="Trial">Trial Plan</option>
							<option value="Basic">Basic Plan</option>
							<option value="Pro">Pro Plan</option>
							<option value="Pro Max">Pro Max Plan</option>
						</select>
					</div>
					<button
					className={`button is-primary mx-3`}
					disabled={!selectedRows || selectedRows.rows.length < 1}
					onClick={() => {
						if (selectedRows && selectedRows.rows.length > 0) {
						const selectedUserData = selectedRows.rows.map(row => {
							let rowData = row.original as AllUsersData;
							return {
								id: rowData.id,
								username: rowData.username,
								email: rowData.email,
								website: rowData.website,
								titles_generated: rowData.titles_generated,
								articles_generated: rowData.articles_generated,
								last_login: rowData.last_login,
								date_joined: rowData.date_joined,
								keywords: rowData.keywords,
								keyword_projects: rowData.keyword_projects,
								plan_name: rowData.plan_name,
							};
						});

						// Convert the selected data to CSV
						const headers = [
							"id", "username", "email", "website", "titles_generated", "articles_generated", 
							"last_login", "date_joined", "keywords", "keyword_projects", "plan_name"];

						const csvRows:Array<String> = [];
						csvRows.push(headers.join(","));

						selectedUserData.forEach(user => {
							const row = headers.map(header => `"${user[header]}"`).join(",");
							csvRows.push(row);
						});

						// Create a Blob from the CSV data
						const csvData = csvRows.join("\n");
						const blob = new Blob([csvData], { type: "text/csv" });

						// Create an anchor element to trigger the download
						const link = document.createElement("a");
						link.href = URL.createObjectURL(blob);
						link.download = "selected-users.csv"; // Set the filename
						link.click();

						selectedRows.rows.forEach(row => {
							row.toggleSelected();
						});
						}
					}}
					>
					Export Selected Users
					</button>
					<button className={`button is-primary mx-3`}
							onClick={handleExportAllUser}>
						Export All Users
					</button>
				</div>
			</div>
			<AbunTable  tableContentName={"Users"}
			             serverSide={true}
						 apiUrl="/api/admin/all-users/"
						 searchboxPlaceholderText={"Search for Users..."}
						 columnDefs={columnDefs}
						 pageSizes={pageSizes}
						 initialPageSize={pageSizes[0]}
						 noDataText={"No Users Found"}
						 dateJoinedValue={dateRange}
						 selectedPlan={selectedPlan}
						 rowCheckbox={true}
                         selectedRowsSetter={selectedRowsSetter}
						
						 transformResponse={(rawData) => {
                            const users = rawData.users || [];
                            let filteredData = users;

                                if (selectedPlan && selectedPlan !== "all") {
                                    filteredData = users.filter((user: any) => user.plan_name === selectedPlan);
                                }
                            return {
                                data: filteredData.map((user: any) => ({
                                        id: user.id,
                                        email: user.email,
                                        username: user.username,
                                        plan_name: user.plan_name,
                                        website: user.website,
                                        titles_generated: user.titles_generated,
                                        articles_generated: user.articles_generated,
                                        keywords: user.keywords,
                                        keyword_projects: user.keyword_projects,
                                        last_login: user.last_login,
                                        date_joined: user.date_joined,
                                })),
                            total: selectedPlan === "all" ? rawData.total : filteredData.length,
                        }}}
                    />
		</div>
	)
}