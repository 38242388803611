import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { NavLink } from "react-router-dom";
import AbunTable from "../../components/AbunTable/AbunTable";

export interface InternalLink {
    url: string;
    title: string;
    summary: string;
    lastScanned: string;
}

interface InternalLinkProps {
    isCrawling: boolean
    websiteDomain: string,
    InternalLinkData: Array<InternalLink>
}

export default function InternalLinks(props: InternalLinkProps) {
    // ---------------------- NON STATE CONSTANTS ----------------------
    const pageSizes = [15, 25, 50, 100];

    // ---------------------- TABLE COLUMN DEFS ----------------------
    const columnHelper = createColumnHelper<InternalLink>();
    const columnDefs: ColumnDef<any, any>[] = [
        columnHelper.accessor((row: InternalLink) => row.url, {
            id: 'url',
            header: "URL",
            cell: props => <NavLink to={props.getValue()} target="_blank">{props.getValue()}</NavLink>,
            enableGlobalFilter: true,
            meta: { align: 'left' }
        }),
        columnHelper.accessor((row: InternalLink) => row.summary, {
            id: 'summary',
            header: "Summary",
            cell: props => (
                <div className="internal-link-summary-cell">
                    <div className="summary-content">{props.getValue()}</div>
                    <div className="summary-tooltip">{props.getValue()}</div>
                </div>
            ),
            enableGlobalFilter: true,
            meta: { align: 'left' }
        }),
        columnHelper.accessor((row: InternalLink) => row.lastScanned, {
            id: 'lastScanned',
            header: "Last Scanned",
            cell: info => info.getValue(),
            enableGlobalFilter: true,
            meta: { align: 'center' }
        }),
    ];

    // ============================================================
    // --------------------- MAIN RENDER CODE ---------------------
    // ============================================================
    if (props.isCrawling) {
        return (
            <div className="analysis-progress-container">
                <div className="analysis-card">
                    <h1 className="analysis-title">Website Analysis in Progress</h1>
                </div>
            </div>
        );
    }
    return (
        <>
            <div className="tab-content w-100">
                <div className="card">
                    <div className="card-content">
                        <div className="mb-4">
                            <h1 className="has-text-centered" style={{ fontSize: "2em", fontWeight: "normal", width: "100%" }}>
                                Internal Links Manager ({props.websiteDomain})
                            </h1>
                        </div>
                        <AbunTable
                            tableContentName={"Internal Links"}
                            tableData={props.InternalLinkData}
                            columnDefs={columnDefs}
                            pageSizes={pageSizes}
                            initialPageSize={pageSizes[0]}
                            noDataText={"No internal links data available."}
                            searchboxPlaceholderText={"Search internal links..."}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}