import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { DateTime } from "luxon";
import Card from "components/Card/Card";
import { Link } from "react-router-dom";
import AbunTable from "../../components/AbunTable/AbunTable";
import "./ArticleLogs.scss";

export interface ArticleLogData {
	title: string
	article_uid: string
	user_email: string
	user_id: number
	user_plan_name: string
	keyword: string
	context: string
	created_on: string
}

export default function ArticleLogs() {
	// ---------------------- NON STATE CONSTANTS ----------------------
	const pageSizes = [50, 100, 150, 300, 500,];

	// ---------------------- TABLE COLUMN DEFS ----------------------
	const columnHelper = createColumnHelper<ArticleLogData>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: ArticleLogData) => row.title, {
			id: 'article_uid',
			header: "Title",
			cell: props => {
				return (
					<Link to={`/view-article/${props.row.original.article_uid}`}>
						<span>{props.row.original.title}</span>
					</Link>
					)
				},
			enableGlobalFilter: true,
		}),
		
		columnHelper.accessor((row: ArticleLogData) => row.user_email, {
			id: 'email_id',
			header: "Email ID",
			cell: props => {
				return (
					<Link to={`/all-users/${props.row.original.user_id}`}>
						<span>{props.row.original.user_email}</span>
					</Link>
					)
				},
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: ArticleLogData) => row.user_plan_name, {
			id: 'user_plan_name',
			header: "Plan",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: ArticleLogData) => row.keyword, {
			id: 'keyword',
			header: "Keyword",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: ArticleLogData) => row.context, {
			id: 'context',
			header: "Context",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: ArticleLogData) => row.created_on, {
			id: 'created_on',
			header: "Created On",
			cell: info => DateTime.fromISO(info.getValue()).setZone("Asia/Kolkata").toLocaleString(DateTime.DATETIME_MED),
			enableGlobalFilter: false,
		})
	]


	// ==============================================================
	// ---------------------- MAIN RENDER CODE ----------------------
	// ==============================================================
	return (
		<div className={"all-articles-container"}>
			<Card>
				<AbunTable tableContentName={"Article Logs"}
							serverSide={true}
							apiUrl="/api/admin/get-article-logs/"
							searchboxPlaceholderText={"Search for Article..."}
							columnDefs={columnDefs}
							pageSizes={pageSizes}
							initialPageSize={pageSizes[0]}
							noDataText={"No Article Found"}
							transformResponse={(rawData) => {
								const articles = rawData.articles;
								let filteredData = articles;

								return {
									data: filteredData.map((article: any) => ({
											title: article.title,
											article_uid: article.article_uid,
											user_email: article.user_email,
											user_id: article.user_id,
											user_plan_name: article.user_plan_name,
											keyword: article.keyword,
											context: article.context,
											created_on: article.created_on,
											
									})),
								total: filteredData.length ? filteredData.length : rawData.total,
							}}}
						/>
			</Card>
		</div>
	)
}