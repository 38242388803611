import { faEye, faEyeSlash, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import abunLogo from "assets/images/branding/abun_blue_text_logo.png";
import { AxiosError } from "axios";
import Alert from "components/Alert/Alert";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import Input from "components/Input/Input";
import { pageURL } from "pages/routes";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { adminLogin } from "utils/api";
import { saveToken } from "utils/jwt";

interface LoginResponse {
	access_token: string
	refresh_token: string
}

export default function Login() {
	// ----------------------- STATES -----------------------
	const [adminEmail, setAdminEmail] = useState("");
	const [adminPassword, setAdminPassword] = useState("");
	const [adminSecret, setAdminSecret] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	// ----------------------- REFS -----------------------
	const errorAlert = useRef<any>(null);
	const navigate = useNavigate();

	// ----------------------- MUTATIONS -----------------------
	const loginMutation = useMutation({
		mutationFn: adminLogin,
		mutationKey: ['adminlogin', adminEmail, adminSecret],
		onSuccess: (data) => {
			// Login Successful. Redirect to dashboard.
			let loginResponse: LoginResponse = data['data'];
			saveToken('access', loginResponse.access_token);
			saveToken('refresh', loginResponse.refresh_token);
			return navigate(pageURL['dashboard']);
		},
		gcTime: 0,
		onError: (error) => {
			const axiosError = error as AxiosError;
			if (axiosError.response?.status === 400) {
				errorAlert.current?.show("Oops! Something went wrong. Login Error: Bad Request");

			} else if (axiosError.response?.status === 403) {
				console.error(axiosError.response?.data);
				errorAlert.current?.show("No such admin user was found. Please make sure credentials are correct.");

			} else {
				console.error(axiosError.response?.data);
				errorAlert.current?.show("Oops! Something went wrong. Please try again in some time.");
			}
		}
	})

	// ----------------------- FUNCTIONS -----------------------
	function loginHandler() {
		if (adminEmail && adminPassword && adminSecret) {
			loginMutation.mutate({email: adminEmail, password: adminPassword, secret: adminSecret});

		} else {
			errorAlert.current?.show("Please fill in all the required fields");
		}
	}

	// ----------------------- RENDER CODE -----------------------
	return (
		<>
			<Card>
				<div className={"auth-card-content"}>
					<img src={abunLogo} alt={"Abun Logo"} className={"auth-card-logo"}/>
					<h3 className={`is-size-3 has-text-centered has-text-primary secondary-font`}>
						Admin Account Login
					</h3>
					{/* ************************ EMAIL ID ************************ */}
					<div className={"field"}>
						<label htmlFor="admin-email" className={"label"}>Email ID:</label>
						<div className={"control"}>
							<Input type={"email"}
										 placeholder={"john@example.com"}
										 id={"admin-email"}
										 value={adminEmail}
										 onChange={(e) => setAdminEmail(e.target.value)}/>
						</div>
					</div>
					{/* ************************ PASSWORD ************************ */}
					<div className={"field"}>
						<label htmlFor="admin-password" className={"label"}>Password:</label>
						<div className={"control"}>
							<Input
										 placeholder={"Minimum 6 characters"}
										 id={"admin-password"}
										 value={adminPassword}
										 type={showPassword ? 'text' : 'password'}
										 onChange={(e) => setAdminPassword(e.target.value)}/>
										  <span
					onClick={() => setShowPassword((prev) => !prev)}
					className={"eyeShow-password"}>
					<FontAwesomeIcon icon={showPassword ? faEye :faEyeSlash} />
                                </span>
						</div>
					</div>
					{/* ************************ SECRET ************************ */}
					<div className={"field"}>
						<label htmlFor="admin-secret" className={"label"}>Admin Secret:</label>
						<div className={"control"}>
							<Input type={"password"}
										 id={"admin-secret"}
										 value={adminSecret}
										 onChange={(e) => setAdminSecret(e.target.value)}/>
						</div>
					</div>
					<Button color={"success"}
									className={"mt-6 is-flex ml-auto mr-auto"}
									disabled={loginMutation.isPending}
									onClick={() => {
										loginHandler();
									}}>
						{loginMutation.isPending ? <>LOGGING IN...&nbsp;<FontAwesomeIcon icon={faSpinner}
																																						 spin={true}/></> : "LOGIN"}
					</Button>
					<div className={"block"}>
						<p className={"has-text-centered mt-5"}>No Admin Account? <Link to={"/signup"}>Sign Up</Link></p>
					</div>
				</div>
			</Card>
			<Alert type={"danger"} ref={errorAlert}/>
		</>
	)
}
