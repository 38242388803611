import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Card from "components/Card/Card";
import { useState } from 'react';
import { AutomationProject } from "../ManageUser/AutomationProjects";
import Articles from "../ManageUser/Articles";
import "./ViewAutomation.scss";

export default function ViewAutomations() {
    const location = useLocation();
    const navigate = useNavigate()
    const selectedProject = location.state.project as AutomationProject;
    const userId = location.state.userId 
    const [showArticles, setShowArticles] = useState(true);
    const [hovered, setHovered] = useState(false);
    const [showTable, setShowTable] = useState(selectedProject.publishedArticleSet.length > 0)
    if (!selectedProject) {
        return <p>No project selected</p>;
    }

    const handleToggle = () => {
        setShowArticles((prevState) => !prevState); // Toggle the visibility of the Articles component
      };

    return (
        <>       
        <Card className={"mt-4 is-flex is-flex-direction-column container-content"}>
            <div className={"keyword-project-header"}>
                    <svg className={"back-btn"} onClick={() => navigate(`/all-users/${userId}`)} stroke="#bcbcbc" fill="#bcbcbc" width="28" height="24" viewBox="0 0 28 24">
                        <path d="M27.5 12H2M2 12L13 1M2 12L13 23" stroke="black" strokeOpacity="0.5" strokeWidth="2" />
                    </svg>
            </div>
            <h3 className="is-size-4 mb-4 has-text-centered has-text-primary">
                {selectedProject.projectName}
            </h3>
            <div className="card-content is-flex is-flex-direction-column">
                <p className="custom-paragraph">
                    <strong className="custom-strong">Project ID:</strong> <span className="uppercase">{selectedProject.projectId}</span>
                </p>
                <p className="custom-paragraph">
                    <strong className="custom-strong">State:</strong> <span className="uppercase">{selectedProject.automationState}</span>
                </p>
                <p className="custom-paragraph">
                    <strong className="custom-strong">Keyword Project:</strong> <NavLink to={`/view-keywords/${selectedProject.selectedKeywordProjectId}`} ><span className="uppercase">{selectedProject.selectedKeywordProjectName}</span></NavLink>
                </p>                
                <p className="custom-paragraph">
                    <strong className="custom-strong">Traffic Range:</strong> <span className="uppercase">{selectedProject.trafficRangeMin} - {selectedProject.trafficRangeMax}</span>
                </p>
                <p className="custom-paragraph">
                    <strong className="custom-strong">Articles Count:</strong> <span className="uppercase">{selectedProject.articlesCount}</span>
                </p>
                <p className="custom-paragraph">
                    <strong className="custom-strong">Frequency:</strong> <span className="uppercase">{selectedProject.frequency}</span>
                </p>
                <p className="custom-paragraph">
                    <strong className="custom-strong">Integration:</strong> <span className="uppercase">{selectedProject.integration}</span>
                </p>
                <p className="custom-paragraph">
                    <strong className="custom-strong">Published Articles Count:</strong> <span className="uppercase">{selectedProject.publishedArticlesCount}</span>
                </p>
                <p className="custom-paragraph">
                    <strong className="custom-strong">Publish State:</strong> <span className="uppercase">{selectedProject.publishState}</span>
                </p>
                <p className="custom-paragraph">
                    <strong className="custom-strong">Publish Days:</strong> <span className="uppercase">{selectedProject.publishDays.join(', ')}</span>
                </p>
                <p className="custom-paragraph">
                    <strong className="custom-strong">Publish Time:</strong> <span className="uppercase">{selectedProject.publishTime}</span>
                </p>
                <p className="custom-paragraph">
                    <strong className="custom-strong">Only Generated Articles:</strong> <span className="uppercase">{selectedProject.publishOnlyGeneratedArticles ? 'Yes' : 'No'}</span>
                </p>
                <p className="custom-paragraph">
                    <strong className="custom-strong">Date Created:</strong> <span className="uppercase">{selectedProject.dateCreated}</span>
                </p>
            </div>
            <div className="view_table">
                <span onClick={handleToggle} style={{ cursor: 'pointer' }} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className="tooltip">
                    <a>{showArticles && showTable 
                        ? 'Hide Table' 
                        : 'View Published Articles'}
                    </a>
                    {!showTable && hovered && (
                    <span className="tooltiptext">
                        No Article
                    </span>
                    )}
                </span>
            </div>
            {(showArticles) && <Articles articles={selectedProject.publishedArticleSet} showDateColumn={true} />}
        </Card>
        </>

    );
}
