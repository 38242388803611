import { ReactNode } from "react";
import "./Card.scss";


interface CardProps {
	children: ReactNode
	header?: string
	className?: string
}


export default function Card(props: CardProps) {
	return (
		<div className={`card ${props.className || ""}`}>
			{props.header && <div className={"card-header"}>
				<div className={"card-header-title"}>{props.header}</div>
			</div>}
			<div className={"card-content"}>
				<div className={"content"}>
					{props.children}
				</div>
			</div>
		</div>
	)
}
