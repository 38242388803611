import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef, createColumnHelper, FilterFn } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import AbunTable from "../../components/AbunTable/AbunTable";
import "./AllUsers.scss";

export interface AllUsersData {
	id: number
	username: string
	email: string
	website: string
	titles_generated: number
	articles_generated: number
	last_login: string
	date_joined: string
	keywords: number
	keyword_projects: number
	plan_name: number
}

export default function AllUsers() {
	// ---------------------- NON STATE CONSTANTS ----------------------
	const pageSizes = [50, 100, 150, 300, 500,];

	// ---------------------- STATES ----------------------
	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;
	const [allUsersData, setAllUsersData] = useState<AllUsersData | null>(null);
	const [selectedPlan, setSelectedPlan] = useState('all');

	// ---------------------- TABLE CUSTOM FILTERS ----------------------
	const joinedDateFilter: FilterFn<any> = (row, columnId, value: [Date, Date]) => {
		if (value[0] === null && value[1] === null) {
			return true
		} else {
			let columnDateString: string = row.getValue(columnId);
			if (columnDateString) {
				let columnDate = DateTime.fromISO(columnDateString);
				let filterStartDate = DateTime.fromISO(value[0].toISOString());
				let filterEndDate = DateTime.fromISO(value[1].toISOString());
				return (filterStartDate <= columnDate) && (filterEndDate >= columnDate)
			} else {
				return false;
			}
		}
	}

	// ---------------------- TABLE COLUMN DEFS ----------------------
	const columnHelper = createColumnHelper<AllUsersData>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: AllUsersData) => row.email, {
			id: 'user_info',
			header: "User Details",
			cell: props => {
				const { username, email, id } = props.row.original;
				return (
					<div>
						<Link to={`/all-users/${id}`}>
                            <div><b>{username}</b></div>
                            <div>{email}</div>
						</Link>
					</div>
				);
			},
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: AllUsersData) => row.plan_name, {
			id: 'plan_name',
			header: "Plan",
			cell: info => <b>{info.getValue()}</b>,
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: AllUsersData) => row.website, {
			id: 'website',
			header: "Website",
			// Note: "---" will still be seen as undefined or null in filter function.
			cell: props => {
				const { website } = props.row.original;
				if (website) {
					const formattedUrl = website.startsWith('http://') || website.startsWith('https://')
						? website
						: `https://${website}`;
		
					return (
						<div>
							<a href={formattedUrl} target="_blank" rel="noopener noreferrer">{website}</a>
						</div>
					);
				}
			},
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: AllUsersData) => row.titles_generated, {
			id: 'titles_generated',
			header: "Usage Stats",
			cell: props => {
				const { titles_generated, articles_generated } = props.row.original;
				return (
					<div>
						<div>Articles: <b>{articles_generated}</b></div>
						<div>Titles: <b>{titles_generated}</b> </div>
					</div>
				);
			},
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: AllUsersData) => row.keywords, {
			id: 'keywords',
			header: "Keywords",
			cell: props => {
				const { keyword_projects, keywords } = props.row.original;
				return (
					<div>
						<div >Keywords: <b>{keywords}</b></div>
						<div>KWs Proj: <b>{keyword_projects}</b></div>
					</div>
				);
			},
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: AllUsersData) => row.last_login, {
			id: 'last_login',
			header: "Date (IST)",
			enableGlobalFilter: false,
			cell: props => {
				const { last_login, date_joined } = props.row.original;
				return (
					<div>
						<div>
							Last Login: {DateTime.fromISO(last_login).setZone("Asia/Kolkata").toLocaleString(DateTime.DATETIME_MED)}
						</div>
						<div>
							Date Joined: {DateTime.fromISO(date_joined).setZone("Asia/Kolkata").toLocaleString(DateTime.DATETIME_MED)}
						</div>
					</div>
				);
			},
		}),
		// columnHelper.display({
		// 	id: 'manage_user',
		// 	header: "Manage User",
		// 	cell: props => {
		// 		return (
		// 			<Link to={props.row.original.id.toString()} className={"button is-primary"}>
		// 				Manage
		// 			</Link>
		// 		)
		// 	},
		// 	enableGlobalFilter: false,
		// 	enableColumnFilter: false,
		// })
	]


	// ==============================================================
	// ---------------------- MAIN RENDER CODE ----------------------
	// ==============================================================
	return (
		<div className={"all-users-container"}>
			<div className={"table-controls table-controls-header"}>
				{/* -------------------------- SEARCH BOX -------------------------- */}
				{/* <Input type={"text"}
							 className={"table-searchbox"}
							 placeholder={"Search for Users..."}
							 value={searchBoxFilter ?? ''}
							 onChange={(e) => {
								 setSearchBoxFilter(String(e.target.value));
							 }}/> */}
				<div className={"table-custom-controls"}>
					<label className={"label is-flex is-align-items-center table-custom-control--item"}>
						<FontAwesomeIcon icon={['far', 'calendar-days']} size={'xl'}/>&nbsp;&nbsp;&nbsp;
						<DatePicker
							selectsRange={true}
							startDate={startDate}
							endDate={endDate}
							onChange={(update) => {
								setDateRange(update);
							}}
							peekNextMonth
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							className={"input"}
							isClearable={true}
							placeholderText={"Click for Date Filter"}
						/>
					</label>
					<div className={"select table-custom-control--item"}>
						<select value={selectedPlan} onChange={e => setSelectedPlan(e.target.value)}>
							<option value="all">All Plans</option>
							<option value="Trial">Trial Plan</option>
							<option value="Basic">Basic Plan</option>
							<option value="Pro">Pro Plan</option>
							<option value="Pro Max">Pro Max Plan</option>
						</select>
					</div>
				</div>
			</div>
			<AbunTable tableContentName={"Users"}
			             serverSide={true}
						 apiUrl="/api/admin/all-users/"
						 searchboxPlaceholderText={"Search for Users..."}
						 columnDefs={columnDefs}
						 pageSizes={pageSizes}
						 initialPageSize={pageSizes[0]}
						 noDataText={"No Users Found"}
						 dateJoinedValue={dateRange}
						 selectedPlan={selectedPlan}
						 transformResponse={(rawData) => {
                            const users = rawData.users || [];
                            let filteredData = users;

                                if (selectedPlan && selectedPlan !== "all") {
                                    filteredData = users.filter((user: any) => user.plan_name === selectedPlan);
                                }
                            return {
                                data: filteredData.map((user: any) => ({
                                        id: user.id,
                                        email: user.email,
                                        username: user.username,
                                        plan_name: user.plan_name,
                                        website: user.website,
                                        titles_generated: user.titles_generated,
                                        articles_generated: user.articles_generated,
                                        keywords: user.keywords,
                                        keyword_projects: user.keyword_projects,
                                        last_login: user.last_login,
                                        date_joined: user.date_joined,
                                })),
                            total: selectedPlan === "all" ? rawData.total : filteredData.length,
                        }}}
                    />
		</div>
	)
}