import "./ViewArticle.scss"

import { useState } from "react";

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getArticleContent, retryFn } from "utils/api";
import { Player } from "@lottiefiles/react-lottie-player";
import PageLoading from "components/PageLoading/PageLoading";
import Markdown from 'markdown-to-jsx';
import Card from "components/Card/Card";
import PageLoadingError from "components/PageLoadingError/PageLoadingError";
import SerperResults from "components/SerperResult/SerperResults";

export default function ViewArticle() {
	// --------------------- REACT ROUTER PARAMS ---------------------
	let { articleUID, jobId } = useParams();

	// ------------------- STATES -------------------
	const [
		articleTitle,
		setArticleTitle
	] = useState("");

	const [
		processing,
		setProcessing
	] = useState(true);

	const [
		articleContent,
		setArticleContent
	] = useState("");

	const [
		articleContext,
		setArticleContext
	] = useState(null);

	const [
		summaryInputs,
		setSummaryInputs
	] = useState("");

	const [
		mergedSummary,
		setMergedSummary
	] = useState("");

	const [
		crewAIOutput,
		setCrewAIOutput
	] = useState("");

	const [
		articleOutline,
		setArticleOutline
	] = useState("");

	const [
		articleFeatureImage,
		setArticleFeatureImage
	] = useState("");

	const [
		serperResult,
		setSerperResult
	] = useState([]);


	// --------------------- QUERY ---------------------
	const {
		isLoading,
		isError,
		error,
		data
	} = useQuery({
		queryKey: ['getArticleContent', articleUID || jobId],
		queryFn: () => getArticleContent(articleUID! || jobId!),
		refetchOnWindowFocus: false,
		retry: retryFn
	})

	// ------------------- FUNCTIONS -------------------
	function renderMainContent() {
		if (processing) {
			return (
				<div className={"is-flex is-flex-direction-column is-align-items-center"}>
					<Player
						autoplay
						loop
						src="https://lottie.host/91a433df-05fa-4ab3-94b2-2c2a0a16a67f/2SoIqH8Kh3.json"
						style={{ height: '300px', width: '300px' }}
					>
					</Player>
					<h2 className={"is-size-3 font-secondary has-text-weight-bold has-text-primary"}>
						Article generation is in progress!
					</h2>
					<p className={"is-size-5 mt-4"}>Article generation should take around 1-2 minutes</p>
				</div>
			)
		} else {
			return (
				<div className={"article-editor-page-editor-container content"} data-color-mode="light">
					<h1>{articleTitle}</h1>
					{articleContent ? <Markdown>{articleContent}</Markdown> : <><h4 className="has-text-centered mt-6 is-size-3">Article Content Not Found</h4></>}
				</div>
			)
		}
	}

	function renderSummaryInputs() {
		if (!processing) {
			let data = JSON.stringify(summaryInputs)
			let inputs = JSON.parse(data)
			return (
				<div className={"article-editor-page-editor-container content"} data-color-mode="light">
					<ul>
						{
							Object.entries(inputs).map(([key, value]) => (
								<li>{`${key}: ${value}`}</li>
							))
						}
					</ul>
				</div>
			)
		}
	}

	function renderArticleOutline() {
		if (!processing) {
			return (
				<div className={"article-editor-page-editor-container content"} data-color-mode="light">
					<ul>
						{
							Object.entries(articleOutline).map(([key, value]) => (
								<li>{`${key}: ${value}`}</li>
							))
						}
					</ul>
				</div>
			)
		}
	}

	// --------------------- EFFECTS ---------------------
	useEffect(() => {
		if (data) {
			setArticleTitle(data['data']['article_title']);
			setArticleContent(data['data']['article_content']);
			setProcessing(data['data']['processing']);
			setArticleContext(data['data']['article_context']);

			// these checks are added for already generated articles
			{ data['data']['summary_inputs'] && setSummaryInputs(data['data']['summary_inputs']); }
			{ data['data']['merged_summary'] && setMergedSummary(data['data']['merged_summary']); }
			{ data['data']['crewai_output'] && setCrewAIOutput(data['data']['crewai_output']); }
			{ data['data']['article_outlines'] && setArticleOutline(data['data']['article_outlines']); }
			{ data['data']['article_feature_image'] && setArticleFeatureImage(data['data']['article_feature_image']); }
			{ data['data']['serper_data']['result'] && setSerperResult(data['data']['serper_data']['result'].slice(0, 3)); }
		}
	}, [data]);

	if (isLoading) return <PageLoading />;

	if (isError) return <PageLoadingError message={error as unknown as string} />;

	return (
		<>
			{serperResult && <>
				<Card>
					<h3 className={"is-size-3 has-text-centered"}>Top Articles</h3>
					<SerperResults results={serperResult} />
				</Card>
			</>}

			{summaryInputs &&
				<div className={"card mt-4 w-100"}>
					<div className={"card-content"}>
						<b>Summary Inputs</b>
						<div className={"content"}>
							{renderSummaryInputs()}
						</div>
					</div>
				</div>}

			{mergedSummary &&
				<div className={"card mt-4 w-100"}>
					<div className={"card-content"}>
						<b>Merged Summary</b>
						<div className={"content"}>
							{mergedSummary}
						</div>
					</div>
				</div>}

			{crewAIOutput &&
				<div className={"card mt-4 w-100"}>
					<div className={"card-content"}>
						<b>Crew AI Output</b>
						<div className={"content"}>
							{crewAIOutput}
						</div>
					</div>
				</div>}

			{articleOutline &&
				<div className={"card mt-4 w-100"}>
					<div className={"card-content"}>
						<b>Outlines</b>
						<div className={"content"}>
							{renderArticleOutline()}
						</div>
					</div>
				</div>}

			{articleFeatureImage &&
				<div className={"card mt-4 w-100"}>
					<div className={"card-content"}>
						<b style={{ display: "block", marginBottom: "1rem", fontSize: "20px" }}>Featured Image</b>
						<img src={articleFeatureImage} alt="Article Featured Image" />
					</div>
				</div>
			}

			{articleContext &&
				<div className={"card mt-4 w-100"}>
					<div className={"card-content"}>
						<b style={{ display: "block", marginBottom: "1rem", fontSize: "20px" }}>Article Context</b>
						<div className={"content"}>
							{articleContext}
						</div>
					</div>
				</div>
			}

			<div className={"card mt-4 w-100"}>
				<div className={"card-content"}>
					<div className={"content"}>
						{renderMainContent()}
					</div>
				</div>
			</div>
		</>
	)
}