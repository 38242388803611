import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	ColumnDef,
	FilterFn,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	PaginationState,
	RowData, RowModel,
	SortingState,
	useReactTable,
} from "@tanstack/react-table";
import React, { forwardRef, HTMLProps, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { authenticateAndFetchData } from '../../utils/api';
import DelayedInput from "../DelayedInput/DelayedInput";

import "./AbunTable.scss";

export interface AbunTableRef {
    refetchData: () => Promise<void>;
}

interface AbunTabelProps {
	tableContentName: string
	tableData?: Array<any>
	columnDefs: ColumnDef<any, any>[]
	pageSizes: Array<number>
	initialPageSize: number
	noDataText: string
	searchboxPlaceholderText: string
	searchDisabled?: boolean
	rowCheckbox?: boolean
	selectedRowsSetter?: (rowModel: RowModel<RowData>) => void
	buttons?: Array<AbunTableButton>
	enableSorting?: boolean
	id?: string
	hidePagination?: boolean
	selectedPlan?: string;
	searchBoxValue?: string
	dateJoinedValue?: null[] | Date[]
	defaultSortingState?: Array<{ id: string, desc: boolean }>

	// server-side props
	serverSide?: boolean
	apiUrl?: string
	queryParams?: Record<string, string>
	transformResponse?: (data: any) => {
		data: any[],
		total: number
	}
	
}

interface AbunTableButton {
	text: string
	type: "primary" | "success" | "warning" | "danger"
	invisible?: boolean
	clickHandler: () => void
}

const stringSearchFilter: FilterFn<any> = (row, columnId, value) => {
	let rowValue: string | number | undefined = row.getValue(columnId);
	if (rowValue !== undefined) {
		let cellValue: string = (rowValue as string | number).toString().toLowerCase();
		return cellValue.includes(value.toLowerCase());
	} else {
		return false;
	}
}


const AbunTable = forwardRef<AbunTableRef, AbunTabelProps>((props, ref) => {

	// --------------------------- STATES ---------------------------
	const [globalFilter, setGlobalFilter] = useState('');
	const [sorting, setSorting] = React.useState<SortingState>(props.defaultSortingState || []);
	const [data, setData] = useState(() => props.tableData ? [...props.tableData] : []);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const columns = useMemo<ColumnDef<any, any>[]>(() => props.columnDefs, [
		props.columnDefs
	])
	const [rowSelection, setRowSelection] = React.useState({});
	const [totalRows, setTotalRows] = useState(0);
	const [{ pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: props.initialPageSize,
	});
	// --------------------------- REFS ---------------------------
	const isInitialMount = useRef(true);
    const previousQueryParams = useRef(props.queryParams);

	useImperativeHandle(ref, () => ({
        refetchData: async () => {
            if (!props.serverSide) return;
            
            await fetchData({
                pageIndex: table.getState().pagination.pageIndex,
                pageSize: table.getState().pagination.pageSize,
                sortBy: sorting,
                globalFilter
            });
        }
    }));

	const table = useReactTable({
		data: props.serverSide ? data : props.tableData ? props.tableData : [],
		columns: columns,
		getCoreRowModel: getCoreRowModel(),
		state: {
			globalFilter,
			rowSelection,
		},
		globalFilterFn: stringSearchFilter,
		onGlobalFilterChange: setGlobalFilter,
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		initialState: {
			pagination: {
				pageSize: props.initialPageSize
			}
		},
		enableRowSelection: props.rowCheckbox,
		onRowSelectionChange: setRowSelection,
		onStateChange(_) {
			setRowSelection({});
		},
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		enableSorting: props.enableSorting ? true : false,
		manualPagination: props.serverSide,
		manualSorting: props.serverSide,
		manualFiltering: props.serverSide,
		pageCount: props.serverSide ? 
			Math.ceil(totalRows / pageSize) : 
			undefined,
	});


	const fetchData = async (params: {
		pageIndex: number
		pageSize: number
		sortBy: SortingState
		globalFilter: string
	}) => {
		if (!props.apiUrl) return;

		setIsDataLoading(true);

		try {
			const queryParams = new URLSearchParams({
				page: (params.pageIndex + 1).toString(),
				per_page: params.pageSize.toString(),
				...(params.globalFilter && { search: params.globalFilter }),
				...(params.sortBy.length > 0 && { 
					sort: params.sortBy.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',') 
				}),
				...props.queryParams
			});

			const response = await authenticateAndFetchData(
				`${props.apiUrl}?${queryParams.toString()}&method=get`
			);
		
			if (!response.status) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const rawData = await response.data;

			const transformedData = props.transformResponse 
				? props.transformResponse(rawData)
				: rawData;

			setData(transformedData.data);
			setTotalRows(transformedData.total);
		} catch (error) {
			console.error('Error fetching data:', error);
			setData([]);
			setTotalRows(0);
		} finally {
			setIsDataLoading(false);
		}
	};
	
	const fetchParams = useMemo(() => ({
        pageIndex: table.getState().pagination.pageIndex,
        pageSize: table.getState().pagination.pageSize,
        sortBy: sorting,
        globalFilter
    }), [
        table.getState().pagination.pageIndex,
        table.getState().pagination.pageSize,
        sorting,
        globalFilter
    ]);
    

	useEffect(() => {
		if (!props.serverSide){
			setData(props.tableData ? [...props.tableData] : []);
		}
	}, [props.tableData]);


	useEffect(() => {
		if (props.searchBoxValue !== undefined) {
			setGlobalFilter(props.searchBoxValue);
		}
	}, [props.searchBoxValue]);

	useEffect(() => {
		if (props.serverSide && props.selectedPlan) {
			fetchData(fetchParams);
		}
	}, [props.selectedPlan, fetchParams]); // Re-fetch data when the selected plan changes
	

	useEffect(() => {
		// props.selectedRowsSetter()
		if (props.rowCheckbox && props.selectedRowsSetter) {
			props.selectedRowsSetter(table.getSelectedRowModel());
		}
	}, [rowSelection]);


	useEffect(() => {
        if (!props.serverSide) return;

        // Initial mount
        if (isInitialMount.current) {
            isInitialMount.current = false;
            fetchData(fetchParams);
            return;
        }
		// Check if queryParams actually changed
        if (JSON.stringify(previousQueryParams.current) !== JSON.stringify(props.queryParams)) {
            previousQueryParams.current = props.queryParams;
            fetchData(fetchParams);
        }
    }, [props.serverSide, props.queryParams]);

	useEffect(() => {
        if (!props.serverSide || isInitialMount.current) return;

        const timer = setTimeout(() => {
            fetchData(fetchParams);
        }, 300);

        return () => clearTimeout(timer);
    }, [fetchParams]);

	function getNoDataTextElement() {
		if (isDataLoading) {
			return <></>
		} else if (globalFilter && table.getRowModel().rows.length <= 0) {
			return (
				<div className={"abun-table-no-data"}>
						{props.noDataText}
					</div>
			)
	
		} else if (table.getRowModel().rows.length <= 0) {
			return (
				<div className={"abun-table-no-data"}>
					No data matching this search query :(<br/>
					Clear the searchbox or try using a different search text.
				</div>
			)

		} else {
			return <></>
		}
	}

	function handlePageNumberChange(newPageNum: string) {
		let num: number;

		try {
			num = parseInt(newPageNum);
			if (num > table.getPageCount()) {
				num = table.getPageCount();
			} else {
				num = Math.max(num, 1)
			}
		} catch (e) {
			num = 1;
		}

		// -1 because page index starts at 0
		table.setPageIndex(num - 1);
	}

	function goToPrevPage() {
		table.setPageIndex(table.getState().pagination.pageIndex - 1);
	}

	function goToNextPage() {
		table.setPageIndex(table.getState().pagination.pageIndex + 1)
	}

	function changePageSize(newSize: string) {
		let newSizeNumber
		try {
			newSizeNumber = parseInt(newSize);
		} catch (e) {
			newSizeNumber = 1
		}
		if (newSizeNumber <= 0) newSizeNumber = 1;
		table.setPageSize(newSizeNumber);
		setPagination(prev => ({
			pageIndex: prev.pageIndex,
			pageSize: newSizeNumber
		}));
	}

	return (
		<div className={"abun-table-responsive"}>
			<div className={"abun-table-container abun-table-container--fullwidth"}>
				{!props.searchDisabled &&
				<DelayedInput initialValue={globalFilter ?? ''}
											delayInMilliseconds={500}
											placeholder={props.searchboxPlaceholderText}
											additionalClasses={['abun-table-search-input']}
											resetInput={undefined}
											onChange={value => {
												setGlobalFilter(String(value));
											}}/>
										}
				<div className={"abun-table-button-container"}>
					{props.buttons?.map((button, index) => (
						<button key={index}
										className={`button is-${button.type}`} style={{display: button.invisible ? "none" : "inline-block"}}
										onClick={button.clickHandler}>
							{button.text}
						</button>
					))}
				</div>
				<table className={"table is-fullwidth"}>
					<thead>
					{table.getHeaderGroups().map(headerGroup => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map(header => (
								<th key={header.id}>
									{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
								</th>
							))}
						</tr>
					))}
					</thead>
					<tbody>

						{isDataLoading ? (
						<tr>
						<td colSpan={columns.length} className="has-text-centered">
							<div >
								<h3 className={"is-size-3 has-text-centered has-text-weight-bold"}>
								Loading Data...&nbsp;<FontAwesomeIcon icon={"spinner"} spin={true}/>
								</h3>
							</div>
						</td>
					</tr>
                        ) : (
                          table.getRowModel().rows.map((row) => (
                            <tr key={row.id}>
                              {row.getVisibleCells().map((cell) => (
                                <td
                                  key={cell.id}
                                    align={(cell.column.columnDef.meta as any)?.align || "left"} >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                              ))}
                            </tr>
                          ))
                        )}
					</tbody>
				</table>
				{getNoDataTextElement()}
				<div className={"abun-table-pagination-controls-container"}>
					<div className={"abun-table-pagination-size-select"}>
						Total <b>{props.serverSide ? totalRows : props.tableData?.length}</b> {props.tableContentName}&nbsp;&nbsp;|&nbsp;&nbsp;Show&nbsp;
						<div className="select is-small">
							<select defaultValue={props.initialPageSize} onChange={e => changePageSize(e.target.value)}>
								{props.pageSizes.map(size => (
									<option key={size} value={size}>{size}</option>
								))}
							</select>
						</div>
					</div>
					&nbsp;
					per page
					<div className={"abun-table-pagination-page-control"}>
						<button className={"chevron-icons"} disabled={!table.getCanPreviousPage()} onClick={goToPrevPage}>
							<FontAwesomeIcon icon={"chevron-left"}/>
						</button>
						<div className={"page-input-container"}>
							<DelayedInput initialValue={(table.getState().pagination.pageIndex + 1).toString()}
														delayInMilliseconds={500}
														resetInput={undefined}
														onChange={handlePageNumberChange}
														additionalClasses={["page-input"]}/>
							&nbsp;/&nbsp;{table.getPageCount()}
						</div>
						<button className={"chevron-icons"} disabled={!table.getCanNextPage()} onClick={goToNextPage}>
							<FontAwesomeIcon icon={"chevron-right"}/>
						</button>
					</div>
				</div>
			</div>
		</div>
	)
})

export function IndeterminateCheckbox(
	{indeterminate, className = '', ...rest}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>
) {
	const ref = React.useRef<HTMLInputElement>(null!)
	React.useEffect(() => {
		if (typeof indeterminate === 'boolean') {
			ref.current.indeterminate = !rest.checked && indeterminate
		}
	}, [ref, indeterminate, rest.checked])

	return (
		<input
			type="checkbox"
			ref={ref}
			className={className + ' cursor-pointer'}
			{...rest}
		/>
	)
}

export default AbunTable;