import Sidebar from "components/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";

export default function LoggedInLayout() {
	return (
		<div className={"main-container"}>
			<Sidebar/>
			<div className={"workspace-container"}>
				{/* added the pages-container div to prevent overlap from navbar */}
				<div className={"pages-container"}>
				<Outlet/>
				</div>
			</div>
		</div>
	)
}
